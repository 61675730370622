<template>
<div>

    <!-- <v-card outlined> -->
    <div id="content">

        <v-row class="text-center mt-1" justify="center" align="center">
            <v-col>
                <h3>جمهورية العراق</h3>
                <h3>مجلس النواب</h3>
                <h3>الأمن</h3>
            </v-col>
            <v-col>
                <img height="140" style="border-radius: 30px" src="../../assets/images/parliament.jpeg" />
            </v-col>
            <v-col>
                <h3>كومارى عيراق</h3>
                <h3>ئه نجومه نى نوينه ران</h3>
                <h3>ئاسايش</h3>
            </v-col>
        </v-row>
        <h3 class="text-center mt-4">تقرير التصاريح الامنية</h3>
        <h3 class="text-center mt-4">-- النتيجة: <v-chip label>
                <h3>{{count}}</h3>
            </v-chip> --</h3>

        <v-data-table id="content" class="mytable" :items-per-page="1000" :items="items" :loading="$global.state.loading" :headers="headers" hide-default-footer :loading-text="$t('loading')" :no-data-text="$t('no-data')">

            <template v-slot:item.attends="{ item }">
                <div class="teal--text" v-if="item.attends.length > 0">
                    {{ new Date(item.attends[0].time).toLocaleDateString("sv").split("T")[0] }},
                    <span>{{new Date(item.attends[0].time).toLocaleString().split(",")[1]}}</span>
                </div>
                <div v-else>
                    <h4>لم يحضر</h4>
                </div>
            </template>

            <template v-slot:item.type="{ item }">
                <div v-for="(type,i) in item.type" :key="i">
                    <v-row class="ma-1">
                        <v-chip small label dark v-if="type == 'بوابة الB3'" color="orange">{{type}}</v-chip>
                        <v-chip small label dark v-if="type == 'بوابة الموظفين'" color="indigo">{{type}}</v-chip>
                        <v-chip small label dark v-if="type == 'بوابة VIP'" color="red">{{type}}</v-chip>
                        <v-chip small label dark v-if="type == 'بوابة الموظفين الداخلية'" color="indigo">{{type}}</v-chip>
                        <v-chip small label dark v-if="type == 'بوابة  VIP الداخلية'" color="red">{{type}}</v-chip>
                    </v-row>
                </div>
            </template>

        </v-data-table>
        <div id="pageFooter">رقم الصفحة </div>
    </div>
    <!-- </v-card> -->
</div>
</template>

<script>
export default {
    data() {
        return {
            items: [],
            fullName: '',
            isReject: '',
            IsAttend: '',
            type: '',
            HasCar: '',
            startAt: '',
            expireAt: '',
            organizationId: '',
            headers: [{
                    text: 'رقم التخويل',
                    value: 'permit.sepuence'
                },
                {
                    text: 'الإسم الثلاثي للزائر',
                    value: 'fullName'
                },
                {
                    text: 'الصفة',
                    value: 'jobTitle'
                },
                {
                    text: 'الجهة الزائرة',
                    value: 'visiting'
                },
                {
                    text: 'الأجهزة',
                    value: 'devices'
                },
                {
                    text: 'نوع العجلة',
                    value: 'carName'
                },
                {
                    text: 'لون العجلة',
                    value: 'carColor'
                },
                {
                    text: 'موديل العجلة',
                    value: 'caryear'
                },
                {
                    text: 'نوع التخويل',
                    value: 'type'
                },
                {
                    text: 'الجهة الطالبة للتخويل',
                    value: 'permit.organization.name'
                },
                {
                    text: 'ملاحظة',
                    value: 'note'
                },
                {
                    text: 'أخر تاريخ ووقت تسجيل حضور',
                    value: 'attends'
                },

            ]
        };
    },

    created() {
        this.getItems()
    },

    methods: {

        async getItems() {
            this.$global.state.loading = true;
            try {
                const res = await this.$http.get(`PermitData?${this.$route.params.filters}`);
                this.items = res.data.result;
                for (let i = 0; i < this.items.length; i++) {
                    this.items[i].type = this.items[i].type.split(',')
                }
                setTimeout(() => window.print(), 1000)
            } catch (err) {
                console.log(err);
                this.$service.faild(err.response.data.message);
            } finally {
                this.$global.state.loading = false;
            }
        },

    },
};
</script>

<style lang="scss" scoped>
#content {
    display: table;
}

#pageFooter {
    display: table-footer-group;
}

#pageFooter:after {
    counter-increment: page;
    content: counter(page);
}

#pageFooter:after {
    counter-increment: page;
    content: "Page "counter(page);
    right: 0;
    top: 100%;
    white-space: nowrap;
    z-index: 20;
    -moz-border-radius: 5px;
    -moz-box-shadow: 0px 0px 4px #222;
    background-image: -moz-linear-gradient(top, #eeeeee, #cccccc);
}
</style>
